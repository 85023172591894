import React from 'react';

import '../styles/experience.scss';
import TabsSection from "./subComponents/TabsComponent";

const Experience = () => {
    const tabs = [
        {
            label: 'Trionika',
            title: 'Front-end developer',
            date: 'Jul 2021 - Present',
            list: [
                'Developed and maintained high-traffic websites (100k+ pages), achieving a 90% improvement in page loading speed through the implementation of an infinite scroll system.',
                'Integrated and enhanced the partner dashboard, contributing to a 17% increase in affiliate program profits.',
                'I collaborated with cross-functional teams using Agile and Scrum methodologies to develop new components and optimize existing ones. This resulted in a more intuitive interface, reducing customer support tickets by over 30% per month.',
                'Successfully launched marketing websites under tight deadlines, helping the company navigate a crisis and increase revenue by 35%.',
                'Established a comprehensive unit testing framework that validated the reliability of 20+ key features, enhancing overall code quality and reducing bug reports by 40% in subsequent releases.',
                'I worked with modern technologies, including React, TypeScript, and data visualization tools like Chart.js.',
            ]
        },
        {
            label: 'Dadvi',
            title: 'Front-end developer',
            date: 'Aug 2020 - Jul 2021',
            list: [
                'Developed over 15 SEO-optimized landing pages and e-commerce websites, increasing client conversion rates by 25% on average and boosting organic traffic by 40%.',
                'Created and integrated websites with Bitrix 1C, enhancing e-commerce functionality.',
                'Collaborated with the marketing team to optimize and refine over 10 client websites, increasing site traffic by 35% and boosting user engagement metrics by 20% on average.',
            ]
        },
        {
            label: 'Dadvi(SEO)',
            title: 'SEO specialist (team lead)',
            date: 'Jan 2016 - Aug 2020',
            list: [
                'Developed and implemented SEO strategies for clients, achieving top rankings in highly competitiveniches (e.g., "luxury real estate," "DDoS protection").',
                'Improved on-page and technical SEO, resulting in significant increases in organic traffic and visibility.'
            ]
        },
    ];

    return (
        <div className='container section_experience section_detect' id={'experience'}>
            <div className={'home_section'}>
                <h2 className={'section_title'}>
                    2. Experience
                </h2>
                <TabsSection tabs={tabs}/>
            </div>
        </div>
    );
};

export default Experience;
import React from 'react';
import '../styles/about.scss';
import Image from "./Image";
import AboutImg from '../img/aboutme.jpg';
import useInViewBlock from './subComponents/UseInViewBlock';

const images = require.context('../img/tech', false, /\.(png|jpe?g|svg)$/);

const About = () => {

    const imagePaths = images.keys().map(key => images(key));
    const [ref, inView] = useInViewBlock();

    return (
        <div className={'container'}>
            <div className={'section_detect home_section about_section'} id='about'>
                <h2 className={'section_title'}>
                    1. About me
                </h2>

                <div ref={ref} className={`about_section__container ${inView ? 'active' : ''}`}>
                    <div className="about_section__image">
                        <div className='img_wrap'>
                            <Image src={AboutImg} alt={`frontend developer`}/>
                        </div>
                    </div>
                    <div className="about_section__text">
                        <p>
                            <span className='img_wrap tab_img'>
                                <Image src={AboutImg} alt={`frontend developer`}/>
                            </span>
                            I started getting into web development back in 2010. At that time, I began creating my first
                            websites using WordPress and ready-made templates. Customizing websites to fit my needs, I
                            gained deep knowledge of CSS and HTML, as well as the basics of Javascript and PHP. Creating
                            websites was a hobby for me.
                        </p>

                        <p>
                            Currently, I have over 4 years of commercial experience in frontend development. During this
                            time, I had the privilege of working in a marketing agency where I focused on developing
                            websites for clients and landing pages to drive traffic. My main emphasis was on development
                            speed, responsiveness, and SEO.
                        </p>
                        <p>
                            Now, I work for Trionika, a company dedicated to building a unique affiliate network in the
                            EdTech and FinTech sectors. My role involves developing new
                            web applications for traffic monetization, as well as maintaining, enhancing, and optimizing
                            existing websites. One of the key directions I am advancing is the development of flexible
                            applications tailored for use in the affiliate program with customizable features for each
                            partner.
                        </p>
                        <p>
                            The primary technology stack I use includes:
                        </p>
                    </div>
                </div>

                <div className="about_section__technology">
                        <div className="carousel-large">
                            {imagePaths.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`} className="slide-large"/>
                            ))}
                        </div>
                </div>

            </div>
        </div>
    );
};

export default About;